// cookies
import cookies from 'js-cookie'
var env = require("../../../env");
// 创建cookies （名字，值，时间）  时间单位为天
function cookieSet (cName, value, expiredays) {
  if (process.env.environment === 'development') {
    cookies.set(cName, value, { expires: expiredays })
  } else {
    cookies.set(cName, value, { expires: expiredays, path: '/', domain: env[process.env.environment].VUE_APP_COOKIE })
  }

  // cookies.set(cName, value, { expires: expiredays, path: '/', domain: '.zhandiana.com' })
  // cookies.set(cName, value, { expires: expiredays, path: '/', domain: '.gcw.net' })
  // cookies.set(cName, value, { expires: expiredays, path: '/', domain: '.jingyingbang.com' })
}

function cookieRead (key, getAll = false) {
  return getAll ? cookies.get() : cookies.get(key)
}

function cookieClear (key) {
  if (process.env.environment === 'development') {
    cookies.remove(key)
  } else {
    cookies.remove(key, { path: '/', domain: env[process.env.environment].VUE_APP_COOKIE })
  }
  // cookies.remove(key, { path: '/', domain: '.zhandiana.com' })
  // cookies.remove(key, { path: '/', domain: '.gcw.net' })
  // cookies.remove(key, { path: '/', domain: '.jingyingbang.com' })
}
const cookie = {
  cookieSet,
  cookieRead,
  cookieClear
}
export default cookie
