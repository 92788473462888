import Vue from 'vue'
import VueLazyload from 'vue-lazyload'
var defalut_url = require('@/assets/url_empty.png')
// Vue.use(VueLazyload)
// 配置项

export default () => {
    Vue.use(VueLazyload, {
        preLoad: 1.3,
        error: defalut_url,
        loading: defalut_url,
        attempt: 3
    })
}