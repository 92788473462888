import Vue from "vue";
import router from "../router/index.js";
import store from "@/store";
// import App from "../layouts/default.vue";
import jingyingbang from "@/utils/index.js";
import ElementUI from "element-ui";
var env = require("../../env");
// import 'element-ui/lib/theme-chalk/display.css'
// import './static/css/flex.css'
import { AppConfig } from "@/config";
import JoinDialog from "vue-join-dialog"
Vue.use(JoinDialog)
// 时间处理库
import moment from "moment";
import "moment/locale/zh-cn";
import base from "@/function/base";
// D:\git\engineering_team\src\assets\icon\iconfont.css
import VueVideoPlayer from "vue-video-player";
import "video.js/dist/video-js.css";
// import VueLazyload from "vue-lazyload";
import infiniteScroll from 'vue-infinite-scroll'
Vue.use(infiniteScroll)
// 视频播放组件
Vue.use(VueVideoPlayer);
import { getPosition } from "@/pages/Home/components/goods/mapPosition.js";
// import BaiduMap from 'vue-baidu-map'
// Vue.use(BaiduMap, {
//   // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
//   ak: 'tiKTrq8gING3nShXh3DqtUsK1wkhMF9i'
// })
moment.locale("zh-cn");
getPosition()
Vue.prototype.$moment = moment;
initErrorReportForVue(Vue,"经营帮大首页")
Vue.use(ElementUI);
Vue.use(jingyingbang);
Vue.use(base); // 将全局函数当做插件来进行注册
Vue.config.productionTip = false;
Vue.prototype.ossUrl = env[process.env.environment].VUE_APP_File_API;
// import VueAMap from 'vue-amap'
// Vue.use(VueAMap)
// VueAMap.initAMapApiLoader({
//   key:'f6506c4fc4b00c2e357ec7748871d61a',
//   plugin: ['AMap.Autocomplete', 'AMap.PlaceSearch', 'AMap.Scale', 'AMap.OverView', 'AMap.ToolBar', 'AMap.MapType', 'AMap.PolyEditor', 'AMap.CircleEditor','MarkerClusterer','AMap.Geocoder','AMap.CitySearch'],
// })

// Vue.use(VueLazyload, {
//   preLoad: 1.5,
//   error:
//     "https://jingyingbang.oss-cn-hangzhou.aliyuncs.com/material/650000/20191028516343562035861.png",
//   loading:
//     "https://jingyingbang.oss-cn-hangzhou.aliyuncs.com/material/650000/20191028516343562035861.png",
//   // loading:
//   //   'https://jingyingbang.oss-cn-hangzhou.aliyuncs.com/pro-images/load.png',
//   attempt: 2
// });
//引入全局的mixin..
import { globalMixins } from "../mixin/global_mixin.js";
Vue.mixin(globalMixins);

function urlredirect() {
  var sUserAgent = navigator.userAgent.toLowerCase();

  if (
    sUserAgent.match(
      /(ipod|iphone os|midp|ucweb|android|windows ce|windows mobile)/i
    )
  ) {
    console.log(AppConfig.wapUrl, "###");

    // debugger;
    window.location.href = AppConfig.wapUrl;
    //PC跳转移动端
  } else {
    // 移动端 跳转到pc端
  }
}
// if (process.env.environment !== 'development') {

urlredirect();
// }

let bus = new Vue();
Vue.prototype.$bus = bus;
Vue.config.errorHandler = function(err, vm, info) {
};
var _hmt = _hmt || [];
window._hmt = _hmt; // 必须把_hmt挂载到window下，否则找不到
(function() {
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?9c658e405f21825d1398173a890581b0";
  var s = document.getElementsByTagName("script")[0];
  s.parentNode.insertBefore(hm, s);
})();

import Router from "vue-router";
const routerPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => error);
};

// 全局埋点方法
// import { loggerHub } from "@/utils/BuryingPoint/loghub.js";
// Vue.prototype.$loggerHub = loggerHub;
// 全局埋点方法结束
// new Vue({
//   router,
//   store,
//   render: h => h(App)
// }).$mount('#app')
