// import link from '@/public/link.js'
var env = require('../../env')
const loginUrl = env[process.env.environment].VUE_APP_PERSONURL
function login() {
  window.location.href = loginUrl + '/login'
}
function sign() {
  window.location.href = loginUrl + '/sign'
}
function personal() {
  window.location.href = loginUrl
}
// 机械出租
function editRent() {
  window.location.href = loginUrl + '/editRent'
}
// 机械求租
function editInquiry() {
  window.location.href = loginUrl + '/editInquiry'
}
// 认证中心 AC(authentication center)
function AC() {
  window.location.href = loginUrl + '/authenticationManagement'
}
// 设备出租
function equipmentRentalAdd() {
  window.location.href = loginUrl + '/equipmentRentalAdd'
}
// equipmentRentalAdd

// 我的消息
function myMessage() {
  window.location.href = loginUrl + '/myMessage'
}

// 工程材料
const materialsUrl = env[process.env.environment].VUE_APP_MATERIALSURL
function index() {
  window.location.href = materialsUrl
}
// 采购订单
function myOrder() {
  window.location.href = loginUrl + '/myOrder'
}
// 招标发布
function tendering() {
  window.location.href = loginUrl + '/tendering'
}
// 查询报价
function purchaserofferrecord() {
  window.location.href = loginUrl + '/purchaserofferrecord'
}
// 预算邀请报价
function offerlist() {
  window.location.href = loginUrl + '/offerlist'
}
// 销售订单
function MySalesOrder() {
  window.location.href = loginUrl + '/MySalesOrder'
}
// 商品发布
function addCommodity() {
  window.location.href = loginUrl + '/addCommodity'
}
// 商机查询
function businessquery() {
  window.location.href = loginUrl + '/businessquery'
}
// 投标记录
function mybidding() {
  window.location.href = loginUrl + '/mybidding'
}
// 报价查询
function offerrecord() {
  window.location.href = loginUrl + '/offerrecord'
}
// 机械发布
function myRent() {
  window.location.href = loginUrl + '/myRent'
}
// 设备发布
function equipmentRental() {
  window.location.href = loginUrl + '/equipmentRental'
}
// 企业/司机/实名认证
function toAuthUser(path) {
  window.location.href = loginUrl + path
}
const link = {
  equipmentRentalAdd,
  login,
  sign,
  personal,
  editRent,
  editInquiry,
  AC,
  myMessage,
  index,
  myOrder,
  tendering,
  purchaserofferrecord,
  offerlist,
  MySalesOrder,
  addCommodity,
  businessquery,
  mybidding,
  offerrecord,
  myRent,
  equipmentRental,
  toAuthUser
}
export default link
