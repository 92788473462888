// 登录 注册 个人中心
import apiUser from '@/public/apiUser/apiUser'
// import router from '@/router/index.js'
// import localStorage from '@/storage/localStorage'
// import store from '@/store'
// async function setArea (arr) {
//   let obj = {}
//   for (let i = 0; i < arr.length; i++) {
//     if (arr[i].checked) {
//       obj = arr[i]
//       break
//     }
//   }
//   localStorage.set('area', JSON.stringify(obj))
//   store.commit('SET_AREA', obj)
//   store.commit('SET_AREALIST', arr)
// }
async function setAreaList () {
  let res = await apiUser.getArea()
  // setArea(res.data.data)
  return res.data.data
}
const areaFunction = {
  setAreaList
}
export default areaFunction
