import Vue from "vue";
// import VueAMap from 'vue-amap';
// 初始化vue-amap

import VueAMap , {initAMapApiLoader} from "@vuemap/vue-amap";
import "@vuemap/vue-amap/dist/style.css";
Vue.use(VueAMap);
// if (process.client) {
  Vue.use(VueAMap);
  initAMapApiLoader({
    // 高德的key
    // key: 'f6506c4fc4b00c2e357ec7748871d61a',
    key: "e26c28d097df4261dbe671e6a7afb5ca",

    // 插件集合
    // plugin: ['AMap.Geolocation', 'AMap.Marker', 'AMap.ToolBar', 'AMap.Circle', 'AMap.PolyLine'],
    plugins: [
      "AMap.Autocomplete",
      "AMap.PlaceSearch",
      "AMap.Scale",
      "AMap.OverView",
      "AMap.ToolBar",
      "AMap.MapType",
      "AMap.PolyEditor",
      "AMap.CircleEditor",
      "MarkerClusterer",
      "AMap.Geocoder",
      "AMap.CitySearch",
      "AMap.Geolocation"
    ],
    AMapUI: "1.0",
    // 高德 sdk 版本，默认为 1.4.4
    version: "2.0"
  });
// }
