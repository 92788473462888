import http from '@/http'
var env = require("../../../env");
// console.log( env[process.env.environment],process.env.NODE_ENV,'@@@@@@@@@@@');

let apiMaterials = env[process.env.environment].VUE_APP_APIMATERIALS
let apiPersonal = env[process.env.environment].VUE_APP_APIUSER
let apiJuri = env[process.env.environment].VUE_APP_ALL_JURI
let option = { 
  headers: { 'Content-Type': 'application/x-www-form-urlencoded', platform:'pc' },
  withCredentials: true
}
const apiUser = {
  getUserInfo: () => http.get(apiPersonal + '/api/user/getCurrentUserInfoV2'),
  // getArea: () => http.get(apiMaterials + '/index/getCurrentProvince'),
  getArea: () => http.get(apiMaterials + '/index/getCurrentProvinceV1'),
  createQrCode: () => http.get(apiPersonal + '/api/qrscan/createQrCode'), // 获取uuid
  queryQRCode: (uuid) => http.get(apiPersonal + '/api/qrscan/queryQRCode', uuid), // 获取二维码返回结果
  getJurisdiction: () => http.get(apiJuri + '/authentication/sv1/user'),
  // 经营帮 企管帮 token 同步
  findSsoUrlList:(data) => http.get(`/sync-ticket/v1/cookie/findSsoUrlList`,data,option ),
  setCookie:(data) => http.get(`${data}`, {} , option),
  checkWhetherOpen: (data) => http.get(apiPersonal + '/ServiceFeeOrder/checkWhetherOpen', data),
  //获取当前用户的关联企业以及是否为此企业超管或管理员
  getCurrentUserRelevanceCompanyInfo: () => http.get(apiPersonal + '/api/user/getCurrentUserRelevanceCompanyInfo'),
  // 检查当前身份是否关联企业
  currentUserRelevanceCompany: () => http.get(apiPersonal + '/api/user/currentUserRelevanceCompany'),
  // 获取当前用户是否认证过团队
  currentUserAuthTeam: () => http.get(apiPersonal + '/api/user/currentUserAuthTeam'),
  
};
export default apiUser
