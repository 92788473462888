import Vue from 'vue'
//导入申请邀请侧边栏
import JoinDialog from 'vue-join-dialog'
import {ZjunFooter, ZjunFeedback} from 'zjkjui' 
import http from '@/http'
// import zjkjui from 'zjkjextend' 
Vue.use(ZjunFooter)
Vue.use(ZjunFeedback, {axios: http})
// Vue.use(zjkjui, {axios: http})
Vue.use(JoinDialog)
