import route from 'vue-router'
import cookie from '@/storage/cookies.js'
import { apiHome } from "@/api/apiHome";
import routes from "@/router/index";
const router = new route({
    mode: 'history',
    // routes: routes
})
export default ({ app, store }) => {
    router.beforeEach(async (to, from, next) => {
        let isClient = process.client
        if (isClient) {
            let token = cookie.cookieRead("token")
            if (!token) {
                try {

                    let Sxyret = await apiHome.memberUser()
                    if (Sxyret.data.code == 0) {
                        cookie.cookieSet("token", Sxyret.data.msg)
                        store.dispatch("setUser")
                        next()
                    } else {
                        next()
                    }
                } catch (error) {
                    next()
                }

            } else {
                next()
            }
        } else {
            next()
        }
    })
    // app.router.afterEach((to, from , next)=>{
    //     // if(process.client){
    //         console.log('app123123')
    //         console.log('app123123', Probe)
    //         // next()
    //     // }
    // })
}